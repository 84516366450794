import * as React from "react"

const NetworkRequests = ({ data }) => {
  const scripts = data.details.items

  const topN = (arr, n) => {
    console.log({ arr })
    if (n > arr.length) {
      return false
    }
    return arr
      .sort((a, b) => {
        return b.transferSize - a.transferSize
      })
      .slice(0, n)
  }

  const worstScripts = topN(data.details.items, 5)


  return (
    <div className="col-span-12 lg:col-span-6 flex flex-col gap-6 bg-secondary rounded-lg px-6 py-6">
      <h3 className="font-bold text-xl text-secondary">
        Network Request Evaluation
      </h3>

      <p>
        Your site loads in a total of {scripts.length} scripts. Take a look at
        the heaviest scripts to see if this is an area for improvement!
      </p>

      <ol className="list-decimal list-inside">
        {worstScripts.map(({ url, transferSize }) => {
          console.log(typeof url)
          return (
            <li>
              <a href={url} className="text-link text-secondary">
                {url.substring(0, 40)}...
              </a>
              <p className="inline font-bold text-primary ml-2">
                {(transferSize /1024 /1024).toFixed(2)} MB
              </p>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default NetworkRequests
