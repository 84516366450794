import * as React from "react"

import { loadModules } from "esri-loader"
import { LocationMarkerIcon } from "@heroicons/react/solid"
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer"
import Basemap from "@arcgis/core/Basemap"
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import Graphic from "@arcgis/core/Graphic"

import LoadingSpinner from "../root/LoadingSpinner"

const EsriMap = ({ lat, lon }) => {
  // Create a ref to element to be used as the map's container
  const mapEl = React.useRef(null)

  // Use a side effect to create the map after react has rendered the DOM
  React.useEffect(() => {
    // define the view here so it can be referenced in the clean up function
    let view
    // the following code is based on this sample:
    // https://developers.arcgis.com/javascript/latest/sample-code/webmap-basic/index.html
    // first lazy-load the esri classes
    loadModules(["esri/views/MapView", "esri/WebMap"], {
      css: true,
    }).then(([MapView, WebMap]) => {
      const vectorTileLayer = new VectorTileLayer({
        portalItem: {
          id: "f3a55a52222341a7aafc793174351bb8", // Forest and Parks Canvas
        },
        opacity: 1,
      })

      const basemap = new Basemap({
        baseLayers: [vectorTileLayer],
      })

      // then we load a web map from an id
      const webmap = new WebMap({
        portalItem: {
          id: "aa1d3f80270146208328cf66d022e09c",
        },
        basemap: basemap,
      })

      // and we show that map in a container
      view = new MapView({
        map: webmap,
        center: [lon, lat],
        zoom: 6,
        // use the ref as a container
        container: mapEl.current,
      })

      const graphicsLayer = new GraphicsLayer()

      webmap.add(graphicsLayer)

      const point = {
        //Create a point
        type: "point",
        longitude: lon,
        latitude: lat,
      }

      const simpleMarkerSymbol = {
        type: "simple-marker",
        color: [226, 119, 40], // Orange
        size: "10px",
        outline: {
          color: [255, 255, 255], // White
          width: 1,
        },
      }

      const pointGraphic = new Graphic({
        geometry: point,
        symbol: simpleMarkerSymbol,
      })

      view.graphics.add(pointGraphic)
    })

    return () => {
      // Clean up the map view
      if (!!view) {
        view.destroy()
        view = null
      }
    }
  }, [])
  return <div className="h-64 w-full" ref={mapEl} />
}

const IpInfoSummary = ({ ipInfo }) => {
  console.log({ ipInfo })
  // If green data is undefined then it is still loading
  if (typeof ipInfo === "undefined") {
    return <LoadingSpinner />
  }
  return (
    <div className="col-span-12 lg:col-span-6 rounded-lg flex flex-col gap-6">
      <h3 className="font-bold text-xl text-primary">ISP Information</h3>
      <div className="gap-6 bg-secondary rounded-lg px-4 py-4">
        <h3 className="text-secondary font-bold text-lg">
          Your request was routed to an ISP in...
        </h3>
        <div className="flex align-center items-center gap-x-2">
          <LocationMarkerIcon className="w-6 h-6 text-secondary" />
          <p className="text-primary font-bold text-2xl">
            {ipInfo.city}, {ipInfo.country}
          </p>
        </div>
        <EsriMap
          id="e691172598f04ea8881cd2a4adaa45ba"
          lat={ipInfo.lat}
          lon={ipInfo.lon}
        />
      </div>
    </div>
  )
}

export default IpInfoSummary
