import * as React from "react"

import { ExternalLinkIcon } from "@heroicons/react/solid"

import { useSocket } from "../../context/socket-context"

import Layout from "../../components/root/Layout"
import GreenDataSummary from "../../components/reports/GreenDataSummary"
import IpInfoSummary from "../../components/reports/IpInfoSummary"
import LighthouseSummary from "../../components/reports/LighthouseSummary"
import AuditScores from "../../components/reports/AuditScores"

const Report = ({ params }) => {
  const { retrieveAudit } = useSocket()
  const audit = retrieveAudit(params.uid)
  // const { greenData, ipInfo, lighthouse, name, url } = audit
  console.log(audit)
  return (
    <Layout mainClassName="flex flex-col">
      <div className="max-w-7xl w-full mx-auto px-6 mt-12">
        <div className="w-full text-left">
          <h1 className="text-4xl text-primary font-bold">{audit?.name}</h1>
          <div className="flex gap-x-4">
            <h2 className="text-xl text-secondary hover:text-yellow-500 font-bold">
              {audit?.url}
            </h2>
            <ExternalLinkIcon className="w-6 h-6 text-secondary" />
          </div>
        </div>

        {/* idea: have one section to show the data for each of the parts */}
        <div className="grid grid-cols-12 pt-10 gap-6 mb-14">
          <AuditScores auditScores={audit?.auditScores}/>
          <GreenDataSummary greenData={audit?.greenData} />
          <IpInfoSummary ipInfo={audit?.ipInfo} />
          <LighthouseSummary lighthouse={audit?.lighthouse} />
        </div>
      </div>
    </Layout>
  )
}

export default Report
