import * as React from "react"
import StatCard from "./StatCard"

const AuditScores = ({ auditScores }) => {
  if (!auditScores) {
    return (
      <div className="col-span-12 w-full bg-secondary rounded-lg h-16 animate-pulse"></div>
    )
  }
  return (
    <div className="col-span-12 w-full">
      <h3 className="font-bold text-xl text-primary mb-6">
        Overall Performance
      </h3>
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 lg:col-span-1">
          <StatCard
            stat={`${typeof auditScores?.hosting === "undefined" ? "-" : auditScores?.hosting }/3`}
            description="Hosting Score"
          />
        </div>
        <div className="col-span-3 lg:col-span-1">
          <StatCard
            stat={`${typeof auditScores?.pageWeight === "undefined" ? "-" : auditScores?.pageWeight }/3`}
            description="Page Weight Score"
          />
        </div>
        <div className="col-span-3 lg:col-span-1">
          <StatCard
            stat={`${typeof auditScores?.performance === "undefined" ? "-" : auditScores?.performance }/3`}
            description="Performance Score"
          />
        </div>
      </div>
    </div>
  )
}

export default AuditScores
