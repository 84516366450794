import * as React from "react"

import Accordion from "../FrequentlyAskedQuestions/Accordion"

const StackSpecific = ({ data }) => {
  return (
    <div className="col-span-12 flex flex-col gap-6 bg-secondary rounded-lg px-6 py-6">
      <h3 className="font-bold text-xl text-secondary">
        Stack Specific Recommendations
      </h3>

      <p className="text-primary font-medium">
        The following list includes some detailed recommendations specific to
        your stack.
      </p>
      <div>
        {data.map(({ title, descriptions }) => {
          return (
            <dl
              className="mt-6 space-y-6 divide-y divide-gray-200 dark:divide-gray-500"
              key={title}
            >
              {Object.entries(descriptions).map(entry => {
                return (
                  <Accordion
                    question={entry[0]}
                    answer={entry[1]}
                    key={entry[0]}
                  />
                )
              })}
            </dl>
          )
        })}
      </div>
    </div>
  )
}

export default StackSpecific
