import * as React from "react"

import Table from "../Table/Table"

const Diagnostics = ({ data }) => {
  const {
    totalByteWeight,
    numStylesheets,
    numScripts,
    numRequests,
    numFonts,
    mainDocumentTransferSize,
  } = data

  const columns = [
    { accessor: "metric", value: "Metric" },
    { accessor: "value", value: "Value" },
  ]

  const tableData = [
    {
      metric: "Total Byte Weight",
      value: totalByteWeight,
    },
    {
      metric: "Number of Style Sheets",
      value: numStylesheets,
    },
    {
      metric: "Number of Scripts",
      value: numScripts,
    },
    {
      metric: "Number of Requests",
      value: numRequests,
    },
    {
      metric: "Number of Fonts",
      value: numFonts,
    },
    {
      metric: "Main Document Transfer Size (Bytes)",
      value: mainDocumentTransferSize,
    },
  ]

  return (
    <div className="col-span-12 lg:col-span-6 flex flex-col gap-6 bg-secondary rounded-lg px-6 py-6">
      <h3 className="font-bold text-xl text-secondary">
        Page Vitals Diagnostics
      </h3>
      <Table columns={columns} data={tableData} />
    </div>
  )
}

export default Diagnostics
