import * as React from "react"

import LoadingSpinner from "../root/LoadingSpinner"
import NetworkRequests from "./NetworkRequests"
import Diagnostics from "./Diagnostics"
import StackSpecific from "./StackSpecific"

const LighthouseSummary = ({ lighthouse }) => {
  console.log({ lighthouse })
  // If green data is undefined then it is still loading
  if (typeof lighthouse === "undefined") {
    return <LoadingSpinner />
  }

  return (
    <div className="col-span-12 w-full">
      <h3 className="font-bold text-xl text-primary mb-6">
        Performance Evaluation
      </h3>
      <div className="grid grid-cols-12 gap-6">
        {/* <div className="col-span-12 lg:col-span-5">
          <StatCard
            stat={lighthouse.score}
            description="Lighthouse Performance Score"
          />
        </div> */}

        <Diagnostics data={lighthouse.audits.diagnostics.details.items[0]} />
        <NetworkRequests data={lighthouse.audits["network-requests"]} />
        {/* 
        <UnusedJavaScriptAndCss
          unusedJavaScript={lighthouse.audits["unused-javascript"]}
          unusedCss={lighthouse.audits["unused-css"]}
        /> */}
        <StackSpecific data={lighthouse.stackPacks} />
      </div>
    </div>
  )
}

export default LighthouseSummary
