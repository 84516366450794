import * as React from "react"

import ReactTooltip from "react-tooltip"
import { DocumentReportIcon, ExternalLinkIcon } from "@heroicons/react/solid"
import Logo from "../../assets/Logo"
import LoadingSpinner from "../root/LoadingSpinner"

const StatCard = ({ className = "", stat, description, isGreen }) => {
  return (
    <div className={`${className} bg-secondary rounded-lg px-4 py-4`}>
      <div
        className={`flex flex-col gap-y-2 relative align-center items-center justify-center h-full`}
      >
        <div
          data-tip={
            isGreen
              ? "Congratulations! You're using a Green Hosting Provider according to the Green Web Foundation"
              : "Unfortunately your site doesn't currently use a Green Hosting Provider according to the Green Web Foundation"
          }
          className="absolute top-0 right-0"
        >
          <Logo
            className={`w-6 h-6 ${isGreen ? "text-green-400" : "text-red-400"}`}
          />
        </div>
        <p className="text-lg text-secondary font-bold text-center">
          {description}
        </p>
        <p className="text-4xl text-primary font-bold text-center">
          {stat || "Unknown"}
        </p>
      </div>
      <ReactTooltip />
    </div>
  )
}

const ExternalLinkWithText = ({ href, text }) => {
  return (
    <a
      className="gap-x-2 font-bold text-primary hover:text-yellow-500"
      href={href}
    >
      {text}
      <ExternalLinkIcon className="w-5 h-5 text-secondary ml-2 inline-block" />
    </a>
  )
}

const SupportingDocuments = ({ data }) => {
  return (
    <div className="bg-secondary rounded-lg px-4 py-4 flex flex-col gap-y-3">
      <div className="flex gap-x-2 align-center items-center">
        <DocumentReportIcon className="w-6 h-6 text-secondary" />
        <p className="font-bold text-secondary text-lg">Supporting Documents</p>
      </div>
      <ol className="list-decimal list-inside">
        {data.map(({ title, link }) => (
          <li key={title}>
            <ExternalLinkWithText href={link} text={title} />
          </li>
        ))}
        {data.length === 0 && <p>No evidence found.</p>}
      </ol>
    </div>
  )
}

const GreenDataSummary = ({ greenData }) => {
  // If green data is undefined then it is still loading
  if (typeof greenData === "undefined") {
    return <LoadingSpinner />
  }

  return (
    <div className="col-span-12 lg:col-span-6 rounded-lg h-full">
      <div className="grid grid-cols-12 gap-6">
        <h3 className="col-span-12 font-bold text-xl text-primary">
          Green Hosting Evaluation
        </h3>
        <StatCard
          className="col-span-12"
          stat={greenData.hosted_by}
          description="Hosting Provider"
          isGreen={greenData.green}
        />
        {greenData.supporting_documents && (
          <div className="col-span-12">
            <SupportingDocuments data={greenData.supporting_documents} />
          </div>
        )}
      </div>
    </div>
  )
}

export default GreenDataSummary
