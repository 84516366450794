import * as React from "react"

const StatCard = ({ stat, description }) => {
  return (
      
    <div className={`bg-secondary rounded-lg px-4 py-4`}>
      <p className="text-lg text-secondary font-bold text-center">
        {description}
      </p>
      <p className="text-4xl text-primary font-bold text-center">{stat}</p>
    </div>
  )
}

export default StatCard
